export let artist_artworks = [
    {
        artist: "Sleepr",
        image_file: "sleepr1.jpg",
        artist_link: "https://twitter.com/sleeprnft",
        homepage_style: "fullscreen",
        paletteBGColor: "black",
        paletteFontColor: "white"

    },
    {
        artist: "Sleepr",
        image_file: "sleepr2.jpg",
        artist_link: "https://twitter.com/sleeprnft",
        homepage_style: "fullscreen",
        paletteBGColor: "black",
        paletteFontColor: "white"

    },
    {
        artist: "Sleepr",
        image_file: "sleepr3.jpg",
        artist_link: "https://twitter.com/sleeprnft",
        homepage_style: "fullscreen",
        paletteBGColor: "black",
        paletteFontColor: "white"

    },
    {
        artist: "Sleepr",
        image_file: "sleepr4.jpg",
        artist_link: "https://twitter.com/sleeprnft",
        homepage_style: "fullscreen",
        paletteBGColor: "black",
        paletteFontColor: "white"

    },
    {
        artist: "Sleepr",
        image_file: "sleepr5.jpg",
        artist_link: "https://twitter.com/sleeprnft",
        homepage_style: "fullscreen",
        paletteBGColor: "black",
        paletteFontColor: "white"

    },
    {
        artist: "Sleepr",
        image_file: "sleepr6.jpg",
        artist_link: "https://twitter.com/sleeprnft",
        homepage_style: "fullscreen",
        paletteBGColor: "sand2",
        paletteFontColor: "white"

    },
    {
        artist: "Sleepr",
        image_file: "sleepr7.jpg",
        artist_link: "https://twitter.com/sleeprnft",
        homepage_style: "fullscreen",
        paletteBGColor: "black",
        paletteFontColor: "white"

    },
    {
        artist: "Celestial Body",
        image_file: "celestial-body.jpg",
        artist_link: "https://twitter.com/celstialbody",
        homepage_style: "block",
        paletteBGColor: "black",
        paletteFontColor: "white"

    },
    {
        artist: "Hans",
        image_file: "hans.jpg",
        artist_link: "https://twitter.com/hans____s",
        homepage_style: "fullscreen",
        paletteBGColor: "black",
        paletteFontColor: "white"

    },
    {
        artist: "Michel Ortega",
        image_file: "michel-ortega.jpg",
        artist_link: "https://twitter.com/michel23ortega",
        homepage_style: "block",
        paletteBGColor: "softpink",
        paletteFontColor: "white"

    },
    {
        artist: "Bibi",
        image_file: "bibi.jpg",
        artist_link: "https://twitter.com/nastia_n_n",
        homepage_style: "block",
        paletteBGColor: "white",
        paletteFontColor: "black"

    },
    {
        artist: "Ewa",
        image_file: "ewa.jpg",
        artist_link: "https://twitter.com/ewa_matyja_",
        homepage_style: "fullscreen",
        paletteBGColor: "purple",
        paletteFontColor: "white"

    },
    {
        artist: "Dana Afanego",
        image_file: "dana-afanego.jpg",
        artist_link: "https://twitter.com/danaAfanego",
        homepage_style: "block",
        paletteBGColor: "sand3",
        paletteFontColor: "white"

    },
    {
        artist: "Hersoid",
        image_file: "hersoid.jpg",
        artist_link: "https://twitter.com/hersoid",
        homepage_style: "block",
        paletteBGColor: "sand3",
        paletteFontColor: "white"

    },
    {
        artist: "Laurence Antony",
        image_file: "laurence-antony.jpg",
        artist_link: "https://twitter.com/laurence_antony",
        homepage_style: "block",
        paletteBGColor: "brightorange",
        paletteFontColor: "white"

    },
    {
        artist: "Fariz",
        image_file: "fariz.jpg",
        artist_link: "https://twitter.com/dulfariz_",
        homepage_style: "fullscreen",
        paletteBGColor: "purple",
        paletteFontColor: "white"

    },
    {
        artist: "BlockFramez",
        image_file: "blockframez.jpg",
        artist_link: "https://twitter.com/BlockFramez",
        homepage_style: "fullscreen",
        paletteBGColor: "purple",
        paletteFontColor: "white"

    },
    {
        artist: "Farhansrdn",
        image_file: "farhansrdn.jpg",
        artist_link: "https://twitter.com/farhannsrdn",
        homepage_style: "block",
        paletteBGColor: "steel",
        paletteFontColor: "white"
    },
    {
        artist: "Calico",
        image_file: "calico.jpg",
        artist_link: "https://twitter.com/33CALICO",
        homepage_style: "block",
        paletteBGColor: "softpink2",
        paletteFontColor: "white"

    },
    {
        artist: "Onerboi",
        image_file: "onerboi.jpg",
        artist_link: "https://twitter.com/onerboi",
        homepage_style: "fullscreen",
        paletteBGColor: "purple",
        paletteFontColor: "white"

    },
    {
        artist: "Ihanaziz",
        image_file: "ihanaziz.jpg",
        artist_link: "https://twitter.com/ihsanaziz_xyz",
        homepage_style: "block",
        paletteBGColor: "blackblack",
        paletteFontColor: "white"

    },
    {
        artist: "DH",
        image_file: "dh.jpg",
        artist_link: "https://twitter.com/dhsken",
        homepage_style: "block",
        paletteBGColor: "jeanblue",
        paletteFontColor: "white"

    },
    {
        artist: "Arseniy Valter",
        image_file: "arseniy-valter.jpg",
        artist_link: "https://twitter.com/post_screw",
        homepage_style: "block",
        paletteBGColor: "sand",
        paletteFontColor: "white"

    },
    {
        artist: "Pigment",
        image_file: "pigment.jpg",
        artist_link: "https://twitter.com/MyartNz",
        homepage_style: "block",
        paletteBGColor: "purple",
        paletteFontColor: "white"

    },
    {
        artist: "Matthew Rock",
        image_file: "matthew-rock.jpg",
        artist_link: "https://twitter.com/DonRoccie",
        homepage_style: "fullscreen",
        paletteBGColor: "purple",
        paletteFontColor: "white"

    },
    {
        artist: "Naj Shukor",
        image_file: "naj-shukor.jpg",
        artist_link: "https://twitter.com/najshukor",
        homepage_style: "block",
        paletteBGColor: "blackblack",
        paletteFontColor: "white"

    },
    {
        artist: "Rian Michelle",
        image_file: "rian-michelle.jpg",
        artist_link: "https://twitter.com/rianmichellle",
        homepage_style: "fullscreen",
        paletteBGColor: "purple",
        paletteFontColor: "white"

    },
    {
        artist: "Awag",
        image_file: "awag.jpg",
        artist_link: "https://twitter.com/aintweallgods",
        homepage_style: "block",
        paletteBGColor: "white",
        paletteFontColor: "black"

    },
    {
        artist: "Imagji Photography",
        image_file: "imajiphotography.jpg",
        artist_link: "https://twitter.com/imajigraphy",
        homepage_style: "fullscreen",
        paletteBGColor: "purple",
        paletteFontColor: "white"

    },
    {
        artist: "Shutterstories ",
        image_file: "shutterstories.jpg",
        artist_link: "https://twitter.com/Jaiywalker1",
        homepage_style: "fullscreen",
        paletteBGColor: "purple",
        paletteFontColor: "white"

    },
    {
        artist: "Dato",
        image_file: "dato.jpg",
        artist_link: "https://twitter.com/dato_art",
        homepage_style: "fullscreen",
        paletteBGColor: "purple",
        paletteFontColor: "white"

    },
    {
        artist: "Michelle Thompson",
        image_file: "michelle-thompson.jpg",
        artist_link: "https://twitter.com/mich_tom",
        homepage_style: "block",
        paletteBGColor: "sand2",
        paletteFontColor: "white"

    },
    {
        artist: "Outsiders",
        image_file: "outsiders.jpg",
        artist_link: "https://twitter.com/errortheory404",
        homepage_style: "fullscreen",
        paletteBGColor: "purple",
        paletteFontColor: "white"

    }

]
