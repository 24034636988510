import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import HomePage from "../Pages/Home";
import PrivacyPolicyPage from "../Pages/PrivacyPolicy";
import TermsOfServicePage from "../Pages/TermsOfService";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms-of-service" element={<TermsOfServicePage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;
