export const truncToOneDecimal = (value) => {
  var num = value;
  return num.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0];
};

export const roundToOndeDecimal = (number) => {
  return Math.round(number * 10) / 10;
};

export const hoursAgo = (timeStamp, hours = 1) => {
  const hour = hours * 1000 * 60 * 60;
  const hourago = Date.now() - hour;
  return timeStamp < hourago;
};
